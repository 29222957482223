import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { useAuthContext } from '../../../utils';
import { Button, Modal } from 'antd';
import Api from '../../../utils/api';

export const ChatComponent = ({
  setMessages,
  messages,
  idToCast,
  focusTrigger,
  lastLogin,
  lastMessage,
  agencyEntity,
}) => {
  const [msgToSend, setMsg] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const { user: adminUser, superAdmin, supportAccountId } = useAuthContext(); // Auth context to get the admin user
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    inputRef.current?.focus();
  }, [focusTrigger]);

  const sendMessage = async () => {
    if (msgToSend.trim() === '') return;

    const newMessage = {
      receiverId: idToCast,
      content: msgToSend,
      timestamp: new Date().toISOString(),
      senderModel: 'User',
      receiverModel: agencyEntity ? 'Agency' : 'User',
    };

    try {
      setMessages(prevMessages => [...prevMessages, newMessage]);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/admin/sendFromSupport`,
        newMessage
      );

      if (response.data) {
        setMessages(prevMessages =>
          prevMessages.map(msg => (msg === newMessage ? response.data : msg))
        );
      }

      setMsg('');
      scrollToBottom();
    } catch (error) {
      console.error('An error occurred:', error);
      setMessages(prevMessages =>
        prevMessages.filter(msg => msg !== newMessage)
      );
    }
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      sendMessage();
    }
  };

  const showConfirmation = () => {
    setIsModalVisible(true);
  };

  const handleRemoveUser = async () => {
    setIsModalVisible(false);

    try {
      // Replace this with your API call to remove the user
      await Api.delete('/admin/removeUser', {
        _id: idToCast,
      });
      console.log('User removed successfully');
    } catch (error) {
      console.error('An error occurred while removing the user:', error);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <div style={styles.chatContainer}>
      <div style={styles.header}>
        <h3 style={styles.title}>Chat</h3>

        <div style={styles.infoContainer}>
          <Button
            type='primary'
            danger
            onClick={showConfirmation}
            disabled={!superAdmin}
          >
            Remove user
          </Button>
          <p style={styles.infoText}>
            Last Login:{' '}
            {lastLogin
              ? format(new Date(lastLogin), 'MMMM dd, yyyy HH:mm')
              : 'N/A'}
          </p>
          <p style={styles.infoText}>
            Last Message:{' '}
            {lastMessage
              ? format(new Date(lastMessage), 'MMMM dd, yyyy HH:mm')
              : 'N/A'}
          </p>
        </div>
      </div>

      <div style={styles.messagesContainer}>
        {messages?.length > 0 ? (
          messages.map(message => {
            const isAdmin =
              message.senderId === supportAccountId ||
              message.senderId === adminUser?._id;
            return (
              <div
                key={message._id}
                style={{
                  ...styles.messageWrapper,
                  justifyContent: isAdmin ? 'flex-end' : 'flex-start',
                }}
              >
                <div
                  style={{
                    ...styles.messageBubble,
                    backgroundColor: isAdmin ? '#DCF8C6' : '#FFF',
                    textAlign: isAdmin ? 'right' : 'left',
                  }}
                >
                  {message.content}
                  <div style={styles.timestamp}>
                    {format(new Date(message.timestamp), 'HH:mm')}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p>No messages available</p>
        )}
        <div ref={messagesEndRef} />
      </div>

      <div style={styles.inputContainer}>
        <input
          ref={inputRef}
          value={msgToSend}
          onChange={e => setMsg(e.target.value)}
          onKeyPress={handleKeyPress}
          style={styles.input}
          placeholder='Type your message'
          inputMode='text'
          autoComplete='off'
          spellCheck='false'
          type='text'
          name={`input-${Math.random()}`}
          id={`input-${Math.random()}`}
        />
        {/* <input
          ref={inputRef}
          value={msgToSend}
          onChange={e => setMsg(e.target.value)}
          onKeyPress={handleKeyPress}
          style={styles.input}
          placeholder='Type your message'
          inputMode='text'
          autoComplete='off'
          type='text'
        /> */}
        <button onClick={sendMessage} style={styles.sendButton}>
          Send
        </button>
      </div>

      {/* Confirmation Modal */}
      <Modal
        title='Remove User'
        visible={isModalVisible}
        onOk={handleRemoveUser}
        onCancel={handleCancel}
        okText='Yes, Remove'
        cancelText='Cancel'
      >
        <p>Are you sure you want to remove this user?</p>
      </Modal>
    </div>
  );
};

// Styles
const styles = {
  chatContainer: {
    background: 'lightgrey',
    padding: 16,
    borderRadius: 8,
    flex: 1,
    maxHeight: '80vh',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
  },
  title: {
    margin: 0,
  },
  infoContainer: {
    textAlign: 'right',
    color: 'gray',
    fontSize: '0.9em',
  },
  infoText: {
    margin: 0,
  },
  messagesContainer: {
    flex: 1,
    overflowY: 'auto',
    paddingBottom: '50px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  messageWrapper: {
    display: 'flex',
    marginBottom: '8px',
  },
  messageBubble: {
    display: 'inline-block',
    color: '#1a1a1a',
    padding: '8px 12px',
    borderRadius: '16px',
    maxWidth: '70%',
    boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
  },
  timestamp: {
    fontSize: '0.8em',
    color: 'gray',
    marginTop: '4px',
    textAlign: 'right',
  },
  inputContainer: {
    display: 'flex',
    marginTop: '16px',
    position: 'sticky',
    bottom: 0,
    backgroundColor: 'lightgrey',
    paddingTop: '8px',
    paddingBottom: '8px',
  },
  input: {
    width: 'calc(100% - 60px)',
    marginRight: '8px',
    padding: '8px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: 16,
  },
  sendButton: {
    padding: '8px 16px',
  },
};

export default ChatComponent;
