import { Button, Grid, Input, Switch } from 'antd';
import { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import Api from '../../../utils/api';
import { useAuthContext } from '../../../utils';

const replaceTurkishChars = str => {
  const turkishMap = {
    ğ: 'g',
    ü: 'u',
    ş: 's',
    ı: 'i',
    ö: 'o',
    ç: 'c',
    Ğ: 'G',
    Ü: 'U',
    Ş: 'S',
    İ: 'I',
    Ö: 'O',
    Ç: 'C',
  };

  return str.replace(/[ğüşıöçĞÜŞİÖÇ]/g, char => turkishMap[char] || char);
};

const Marketing = () => {
  const { appSettings, updateAppSettings, superAdmin } = useAuthContext();

  const [data, setData] = useState([]);
  const [batchSize, setBatchSize] = useState(
    appSettings.campaignBatchSize ?? 0
  );

  useEffect(() => {
    if (appSettings?.campaignBatchSize !== undefined) {
      setBatchSize(appSettings.campaignBatchSize);
    }
  }, [appSettings?.campaignBatchSize]);
  const handleFileUpload = event => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsBinaryString(file);

    reader.onload = e => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(sheet);

      const transformedData = parsedData.map(row =>
        Object.fromEntries(
          Object.entries(row).map(([key, value]) => [
            replaceTurkishChars(key.replace(/\s+/g, '_'))
              .replace(/[.()]/g, '')
              .toLowerCase(),
            typeof value === 'string' ? value.toLowerCase() : value,
          ])
        )
      );

      setData(transformedData);
    };
  };

  const submit = () => {
    Api.post('/admin/addLeads', { data });
  };

  const handleToggleCampaign = async () => {
    const res = await Api.post('/admin/updateSettings', {
      dailyEmailCampaign: !appSettings.dailyEmailCampaign,
    });
    updateAppSettings(res);
  };

  const handleUpdateBatchSize = async () => {
    const res = await Api.post('/admin/updateSettings', {
      campaignBatchSize: batchSize,
    });
    updateAppSettings(res);
  };

  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const isSmallScreen = screens.md;

  if (!superAdmin) {
    return <div>This is danger zone</div>;
  }

  return (
    <div className='p-4'>
      <div
        style={{
          display: 'flex',
          gap: 8,
          justifyContent: 'space-between',
          flexDirection: !isSmallScreen ? 'column' : 'row',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', gap: 8 }}>
          <Input type='file' accept='.xlsx, .xls' onChange={handleFileUpload} />
          <Button onClick={submit}>Submit</Button>
        </div>

        <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
          <Switch
            onClick={handleToggleCampaign}
            checked={appSettings.dailyEmailCampaign}
            style={{ maxWidth: 100 }}
          />
          <Input
            type='number'
            value={batchSize}
            onChange={e => setBatchSize(Number(e.target.value))}
            style={{ width: 100 }}
          />
          <Button onClick={handleUpdateBatchSize}>Save</Button>
        </div>
      </div>

      <span>{appSettings?.availableCountries?.map(c => c).join(', ')}</span>
      {data.length > 0 && (
        <table className='border-collapse border border-gray-400 mt-4'>
          <thead>
            <tr>
              {Object.keys(data[0]).map(key => (
                <th key={key} className='border border-gray-400 p-2'>
                  {key}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                {Object.values(row).map((value, idx) => (
                  <td key={idx} className='border border-gray-400 p-2'>
                    {value}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default Marketing;
