import React, { useCallback, useEffect, useState } from 'react';
import { Dropdown } from '../../common';
import countries from '../OfferPortal/countries';
import { Input, Tooltip, Button, notification } from 'antd';
import { useAuthContext } from '../../../utils';
import Api from '../../../utils/api';
import ChartComponent from './Chart';

const { TextArea } = Input;

const Metrics = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getMetrics();
  }, []);

  const getMetrics = async () => {
    const res = await Api.get('/admin/getMetrics');
    setData(res);
  };

  return (
    <div
      style={{
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        height: '100%',
        maxHeight: 1200,
      }}
    >
      <h2 style={{ marginBottom: '20px' }}>Metrics (In Progress)</h2>

      <Dropdown
        style={{ width: 100 }}
        defaultValue={7}
        options={[7, 30, 60, 90, 180]}
        onChange={async value => {
          const res = await Api.get(`/admin/getMetrics`, { days: value });
          setData(res);
        }}
      />

      <ChartComponent data={data} />
    </div>
  );
};

export default Metrics;
