import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Api from '../utils/api';

const Unsubscribe = () => {
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('Processing your request...');

  useEffect(() => {
    const token = searchParams.get('token');

    if (!token) {
      setMessage('Invalid unsubscribe link.');
      return;
    }

    (async () => {
      try {
        const res = await Api.post('/users/unsubscribe', { token });
        if (!res) return;
        if (res.success) {
          setMessage('You have successfully unsubscribed.');
          setTimeout(() => {
            window.location.href = 'https://oceanlabs.app';
          }, 3000);
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <h2>{message}</h2>
    </div>
  );
};

export default Unsubscribe;
