import React, { useEffect, useRef } from 'react';
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { format } from 'date-fns';

// Register Chart.js components
Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  CategoryScale,
  Tooltip,
  Legend
);

const ChartComponent = ({ data }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // Process data: Group by day
  const processData = () => {
    const dailyMetrics = {};

    data.forEach(item => {
      const day = format(new Date(item.createdAt), 'MMM dd, yyyy'); // Convert timestamp to day format

      if (!dailyMetrics[day]) {
        dailyMetrics[day] = {
          agencyCount: 0,
          userCount: 0,
          recentlyLoggedInAgencies: 0,
          recentlyLoggedInUsers: 0,
          worksOpened: 0,
          offersSent: 0,
        };
      }

      dailyMetrics[day].agencyCount = item.agencyCount; // Assuming this is a total count
      dailyMetrics[day].userCount = item.userCount; // Assuming this is a total count
      dailyMetrics[day].recentlyLoggedInAgencies +=
        item.recentlyLoggedInAgencies;
      dailyMetrics[day].recentlyLoggedInUsers += item.recentlyLoggedInUsers;
      dailyMetrics[day].worksOpened += item.worksOpened;
      dailyMetrics[day].offersSent += item.offersSent;
    });

    return {
      labels: Object.keys(dailyMetrics),
      agencyCount: Object.values(dailyMetrics).map(d => d.agencyCount),
      userCount: Object.values(dailyMetrics).map(d => d.userCount),
      recentlyLoggedInAgencies: Object.values(dailyMetrics).map(
        d => d.recentlyLoggedInAgencies
      ),
      recentlyLoggedInUsers: Object.values(dailyMetrics).map(
        d => d.recentlyLoggedInUsers
      ),
      worksOpened: Object.values(dailyMetrics).map(d => d.worksOpened),
      offersSent: Object.values(dailyMetrics).map(d => d.offersSent),
    };
  };

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy(); // Destroy previous chart instance
    }

    const {
      labels,
      agencyCount,
      userCount,
      recentlyLoggedInAgencies,
      recentlyLoggedInUsers,
      worksOpened,
      offersSent,
    } = processData();

    const ctx = chartRef.current.getContext('2d');

    chartInstance.current = new Chart(ctx, {
      type: 'line',
      data: {
        labels,
        datasets: [
          {
            label: 'Total Agencies',
            data: agencyCount,
            borderColor: 'blue',
            backgroundColor: 'rgba(0, 0, 255, 0.2)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Total Users',
            data: userCount,
            borderColor: 'purple',
            backgroundColor: 'rgba(128, 0, 128, 0.2)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Recently Logged-in Agencies',
            data: recentlyLoggedInAgencies,
            borderColor: 'green',
            backgroundColor: 'rgba(0, 255, 0, 0.2)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Recently Logged-in Users',
            data: recentlyLoggedInUsers,
            borderColor: 'cyan',
            backgroundColor: 'rgba(0, 255, 255, 0.2)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Works Opened',
            data: worksOpened,
            borderColor: 'red',
            backgroundColor: 'rgba(255, 0, 0, 0.2)',
            borderWidth: 2,
            fill: false,
          },
          {
            label: 'Offers Sent',
            data: offersSent,
            borderColor: 'orange',
            backgroundColor: 'rgba(255, 165, 0, 0.2)',
            borderWidth: 2,
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            enabled: true,
            mode: 'index',
            intersect: false,
            callbacks: {
              title: tooltipItems => `📅 ${tooltipItems[0].label}`, // Display date as the title
              label: tooltipItem => {
                const datasetLabel = tooltipItem.dataset.label || '';
                const value = tooltipItem.raw;
                return `📊 ${datasetLabel}: ${value}`; // Show dataset name and value
              },
            },
          },
          legend: {
            display: true,
            position: 'top',
          },
        },
        scales: {
          x: {
            title: {
              display: true,
              text: 'Day',
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: 'Count',
            },
          },
        },
      },
    });

    return () => {
      if (chartInstance.current) chartInstance.current.destroy();
    };
  }, [data]);

  return (
    <div style={{ width: '100%', height: 400 }}>
      <canvas ref={chartRef} />
    </div>
  );
};

export default ChartComponent;
