import React, { useState, useEffect, useRef } from 'react';
import {
  ChatContainer,
  ChatBox,
  ChatMessage,
  ChatInputForm,
  Input,
  SendButton,
  ErrorMessage,
  ContentWrapper,
  StyledTypingIndicator,
} from './styles';
import { useAuthContext } from '../../../utils';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Skeleton } from 'antd';
import io from 'socket.io-client';

export const ArtificialIntelligence = () => {
  const [userMessage, setUserMessage] = useState('What is OceanLabs ?');
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const chatBoxRef = useRef(null);
  const messagesEndRef = useRef(null);
  const inputRef = useRef(null);
  const socketRef = useRef(null);
  const assistantMessageRef = useRef(''); // Use ref for synchronous updates
  const { aIModel, user, token } = useAuthContext();

  useEffect(() => {
    // Focus input when not loading
    if (!loading) {
      inputRef.current?.focus();
    }
  }, [loading]); // Trigger when loading state changes

  useEffect(() => {
    // Initial focus on component mount
    inputRef.current?.focus();
  }, []); // Empty array = runs only once on mount

  useEffect(() => {
    if (!token || !user?._id) return;

    const initializeSocket = () => {
      socketRef.current = io(process.env.REACT_APP_WS_URL, {
        query: { token },
        reconnection: true,
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
      });

      socketRef.current.on('connect', () => {
        console.log(`Connected to WebSocket as user ${user._id}`);
        setError('');
      });

      socketRef.current.on('AI_STREAM', newMessage => {
        try {
          if (!newMessage?.choices?.[0]?.delta?.content) return;

          const messageChunk = newMessage.choices[0].delta.content;
          assistantMessageRef.current += messageChunk;

          requestAnimationFrame(() => {
            setConversation(prev => {
              const last = prev[prev.length - 1];
              // Only update if we have actual content
              if (last?.role === 'assistant' && assistantMessageRef.current) {
                return [
                  ...prev.slice(0, -1),
                  { ...last, content: assistantMessageRef.current },
                ];
              }
              return prev;
            });
          });
        } catch (error) {
          console.error('Error processing chunk:', error);
        }
      });

      socketRef.current.on('AI_STREAM_END', () => {
        console.log('stearm ended');
        setLoading(false);
        // Clean up empty assistant messages
        setConversation(prev => prev.filter(msg => msg.content.trim() !== ''));
        assistantMessageRef.current = '';
        scrollToBottom();
      });

      socketRef.current.on('connect_error', err => {
        console.error('Connection error:', err);
        setError('Connection lost. Reconnecting...');
      });
    };

    if (!socketRef.current) initializeSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.off('AI_STREAM');
        socketRef.current.off('AI_STREAM_END');
        socketRef.current.off('connect_error');
      }
    };
  }, [token, user?._id]);

  const handleInputChange = e => setUserMessage(e.target.value);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation]);

  const fetchStreamData = async e => {
    e.preventDefault();
    if (!userMessage.trim() || loading) return;

    try {
      setLoading(true);
      setError('');

      // Add user message immediately
      setConversation(prev => [
        ...prev.filter(msg => msg.content.trim() !== ''), // Clean any previous empty messages
        { role: 'user', content: userMessage },
        { role: 'assistant', content: '▌' }, // Add typing indicator
      ]);
      // Reset ref for new message
      assistantMessageRef.current = '';
      setUserMessage('');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/ai/askStream`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ userMessage, model: aIModel }),
        }
      );

      if (!response.ok) throw new Error('AI request failed');
    } catch (error) {
      console.error('Request error:', error);
      setError('Failed to start conversation');
      setLoading(false);
      setConversation(prev => prev.filter(msg => msg.content.trim() !== ''));
    }
  };

  return (
    <ChatContainer>
      <h2>Chat with OceanLabs Support</h2>
      {conversation.length > 0 && (
        <ChatBox ref={chatBoxRef}>
          {conversation
            .filter(message => message.content.trim() !== '')
            .map((message, index) => (
              <ChatMessage key={index} role={message.role}>
                <ContentWrapper>
                  <ReactMarkdown remarkPlugins={[remarkGfm]}>
                    {message.content.replace(/▌$/, '')}
                  </ReactMarkdown>
                  {message.content.endsWith('▌') && <StyledTypingIndicator />}
                </ContentWrapper>
              </ChatMessage>
            ))}
          <div ref={messagesEndRef} />
        </ChatBox>
      )}

      <ChatInputForm onSubmit={fetchStreamData}>
        <Input
          value={userMessage}
          onChange={handleInputChange}
          ref={inputRef}
          placeholder='Type your message...'
          disabled={loading}
          required
        />
        <SendButton type='submit' disabled={loading || !userMessage.trim()}>
          {loading ? 'Responding...' : 'Send'}
        </SendButton>
      </ChatInputForm>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ChatContainer>
  );
};
