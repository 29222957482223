import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import io from 'socket.io-client';
import { ToggleSwitch } from '../../common';
import { UserList } from './UserList';
import { ChatComponent } from './ChatComponent';
import { useAuthContext } from '../../../utils';
import Api from '../../../utils/api';

export const Users = () => {
  const { token, superAdmin, user } = useAuthContext();
  const [users, setUsers] = useState([]);
  const [agencies, setAgencies] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [isUserPage, setIsUserPage] = useState(superAdmin);
  const [focusTrigger, setFocusTrigger] = useState(0);
  const socket = useRef(null); // WebSocket reference
  const audioRef = useRef(new Audio('/sounds/notification.mp3')); // Audio reference

  const adminEmail = 'oooytun@icloud.com';

  // Establish WebSocket connection on mount
  useEffect(() => {
    if (token) {
      socket.current = io(process.env.REACT_APP_WS_URL, { query: { token } });

      socket.current.on('connect', () => {
        console.log('Connected to WebSocket', socket.current.id);
      });

      // Listen for new messages and update the messages state
      socket.current.on('RECEIVE_MESSAGE', newMessage => {
        if (!newMessage.senderId || !newMessage.receiverId) {
          console.warn('Malformed message received:', newMessage);
          return;
        }

        // Play notification sound and update messages
        audioRef.current.play();
        if (
          newMessage.senderId === selectedEntity?._id ||
          newMessage.receiverId === selectedEntity?._id
        ) {
          setMessages(prevMessages => [...prevMessages, newMessage]);
        }
      });

      socket.current.on('disconnect', reason => {
        console.log('Disconnected from WebSocket:', reason);
      });

      return () => socket.current?.disconnect();
    }
  }, [token, selectedEntity]);

  const setHeaders = () => {
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
    }
  };

  const getEntities = async () => {
    setHeaders();
    try {
      const response = await Api.get('/admin/getUsers');
  
      // Sort users by most recent `lastMessage`
      const filteredUsers = response.users
        .filter(user => user.email !== adminEmail)
        .sort((a, b) => {
          const dateA = a.lastMessage ? new Date(a.lastMessage).getTime() : 0;
          const dateB = b.lastMessage ? new Date(b.lastMessage).getTime() : 0;
          return dateB - dateA; // Most recent first
        });
  
      // Sort agencies by most recent `lastMessage`
      const sortedAgencies = response.agencies.sort((a, b) => {
        const dateA = a.lastMessage ? new Date(a.lastMessage).getTime() : 0;
        const dateB = b.lastMessage ? new Date(b.lastMessage).getTime() : 0;
        return dateB - dateA; // Most recent first
      });
  
      setUsers(filteredUsers);
      setAgencies(sortedAgencies);
  
      // Ensure we're selecting from the sorted version
      const entities = isUserPage ? filteredUsers : sortedAgencies;
      const currentSelected = entities.find(
        entity => entity._id === selectedEntity?._id
      );
      setSelectedEntity(currentSelected || entities[0]);
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };
  
  useEffect(() => {
    getEntities();
  }, [token, isUserPage]);

  const handleToggle = () => {
    if(!superAdmin) return

    setIsUserPage(!isUserPage);
    setSelectedEntity(null);
    setMessages([]);
  };

  const getMessageHistory = useCallback(async () => {
    if (!selectedEntity?._id) {
      console.error('No entity selected for fetching messages.');
      return;
    }

    try {
      const response = await Api.get(`/admin/getHistory`, {
        userId: selectedEntity._id,
      });

      setMessages(response.messages);
    } catch (error) {
      console.error('An error occurred while fetching message history:', error);
    }
  }, [selectedEntity, token]); // Dependencies of the function

  useEffect(() => {
    getMessageHistory();
  }, [getMessageHistory]); // Use getMessageHistory in the dependency array

  useEffect(() => {
    if (selectedEntity) {
      setFocusTrigger(prev => prev + 1);
    }
  }, [selectedEntity]);

  const agenciesWithNames = agencies.map(agency => {
    return { ...agency, firstName: agency.name };
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        maxHeight: '100%',
      }}
    >
      <div style={{ alignSelf: 'start' }}>
        <ToggleSwitch checked={isUserPage} onToggle={handleToggle} />
      </div>

      <div style={{ display: 'flex', gap: 8 }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto',
            maxHeight: '80vh',
          }}
        >
          <UserList
            users={isUserPage ? users : agenciesWithNames}
            selectedUser={selectedEntity}
            setSelectedUser={setSelectedEntity}
          />
        </div>

        <div style={{ flex: 1 }}>
          <ChatComponent
            idToCast={selectedEntity?._id}
            messages={messages}
            setMessages={setMessages}
            focusTrigger={focusTrigger}
            lastLogin={selectedEntity?.lastLogin}
            lastMessage={selectedEntity?.lastMessage}
            agencyEntity={!!selectedEntity?.agency}
          />
        </div>
      </div>
    </div>
  );
};

export default Users;
