import React from 'react';
import { useAuthContext } from './authContext';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuthContext();

  if (!isAuthenticated) {
    return <Navigate to='/admin-x9z7q1w3t8' />;
  }

  return children;
};

export default ProtectedRoute;
