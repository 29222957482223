import {
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Api from './api';

const AuthContext = createContext({
  user: {},
  token: '',
  isAuthenticated: false,
  loading: true,
  setUser: () => {},
  setToken: () => {},
  aIModel: '',
  setaIModel: () => {},
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [appSettings, setAppSettings] = useState({});
  const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(token));
  const [loading, setLoading] = useState(true);
  const [aIModel, setaIModel] = useState(localStorage.getItem('aIModel') || ''); // Load from localStorage

  const [supportAccountId, setSupportAccountId] = useState(
    localStorage.getItem(null)
  ); // Load from localStorage

  const getAppSettings = async () => {
    const res = await Api.get('/admin/getAppSettings');
    setAppSettings(res);
  };

  const navigate = useNavigate();

  // Save AI model selection to localStorage
  useEffect(() => {
    if (aIModel) {
      localStorage.setItem('aIModel', aIModel);
    }
  }, [aIModel]);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common = { Authorization: `Bearer ${token}` };
      localStorage.setItem('token', token);
      setIsAuthenticated(true);
    } else {
      delete axios.defaults.headers.common.Authorization;
      localStorage.removeItem('token');
      setIsAuthenticated(false);
    }
  }, [token]);

  useEffect(() => {
    if (!token) {
      setLoading(false);
      return;
    }

    const loginSilently = async () => {
      try {
        const response = await Api.post(`/admin/loginWithToken`, { token });

        if (response === 'Oopsy!') {
          setIsAuthenticated(false);
          setToken('');
          navigate('/admin-x9z7q1w3t8');
        } else if (response?.user) {
          setUser(response.user);
          getAppSettings();
          if (response.supportAccountId) {
            setSupportAccountId(response.supportAccountId);
          }
          setIsAuthenticated(true);
        } else {
          console.error('Unexpected API response:', response);
          setToken('');
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Silent login failed:', error);
        setToken('');
        setIsAuthenticated(false);
        navigate('/admin-x9z7q1w3t8');
      } finally {
        setLoading(false);
      }
    };

    loginSilently();
  }, [token, navigate]);

  const updateAppSettings = setting => {
    setAppSettings({ ...appSettings, ...setting });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        token,
        setToken,
        isAuthenticated,
        loading,
        superAdmin: user?.email === 'oooytun@icloud.com',
        setIsAuthenticated,
        setaIModel,
        aIModel,
        supportAccountId,
        appSettings,
        updateAppSettings,
        setSupportAccountId,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
