import React from 'react';

const PrivacyPolicy = () => (
  <div
    className='privacy-policy'
    style={{
      padding: '20px',
      maxWidth: '800px',
      margin: '0 auto',
      backgroundColor: '#1a1a1a', // Dark background
      color: '#ffffff', // Light text for readability
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    }}
  >
    <h2 style={{ color: '#ffffff' }}>Privacy Policy</h2>
    <p style={{ color: '#cccccc' }}>Last Updated: 4 November 2024</p>

    <h3 style={{ color: '#ffffff' }}>1. Introduction</h3>
    <p style={{ color: '#cccccc' }}>
      Welcome to Ocean Labs! Your privacy is important to us. This Privacy
      Policy explains how we collect, use, and protect your information when you
      use our services. By using our app, you agree to the terms outlined in
      this policy.
    </p>

    <h3 style={{ color: '#ffffff' }}>2. Information We Collect</h3>

    <h4 style={{ color: '#ffffff' }}>Personal Information</h4>
    <p style={{ color: '#cccccc' }}>
      We may collect personal information that you provide, such as your name,
      email address, phone number, and any other information you choose to share
      when you sign up or contact us.
    </p>

    <h4 style={{ color: '#ffffff' }}>Location Data</h4>
    <p style={{ color: '#cccccc' }}>
      Our app collects your location data to enable location-based services,
      such as showing nearby users and sending relevant notifications. Depending
      on your app settings, this data may be collected even when the app is
      running in the background.
    </p>
    <p style={{ color: '#cccccc' }}>
      You can manage this feature in your device settings or within the app.
      However, disabling background location tracking may limit certain
      functionalities.
    </p>

    <h4 style={{ color: '#ffffff' }}>Usage Data</h4>
    <p style={{ color: '#cccccc' }}>
      We automatically collect information about your interactions with our app,
      including the pages you visit, timestamps, and actions you take. This
      helps us improve our services.
    </p>

    <h4 style={{ color: '#ffffff' }}>Device Information</h4>
    <p style={{ color: '#cccccc' }}>
      We may collect data about the device you use to access our app, including
      device type, operating system, and unique device identifiers.
    </p>

    <h3 style={{ color: '#ffffff' }}>3. How We Use Your Information</h3>
    <p style={{ color: '#cccccc' }}>
      We use the collected data for the following purposes:
    </p>
    <ul>
      <li style={{ color: '#cccccc' }}>To provide and maintain our services</li>
      <li style={{ color: '#cccccc' }}>To notify you of service updates</li>
      <li style={{ color: '#cccccc' }}>To provide customer support</li>
      <li style={{ color: '#cccccc' }}>
        To improve app performance and user experience
      </li>
      <li style={{ color: '#cccccc' }}>To monitor usage and analyze trends</li>
      <li style={{ color: '#cccccc' }}>To comply with legal obligations</li>
      <li style={{ color: '#cccccc' }}>
        To enable location-based features, such as displaying nearby users and
        sending location-related notifications
      </li>
    </ul>

    <h3 style={{ color: '#ffffff' }}>4. Sharing of Information</h3>
    <p style={{ color: '#cccccc' }}>
      We may share your information with third parties under the following
      circumstances:
    </p>
    <ul>
      <li style={{ color: '#cccccc' }}>
        With service providers who help us operate our business and app
      </li>
      <li style={{ color: '#cccccc' }}>
        When required by law or to protect our rights and safety
      </li>
      <li style={{ color: '#cccccc' }}>
        In connection with a business transfer, such as a merger or acquisition
      </li>
    </ul>

    <h3 style={{ color: '#ffffff' }}>5. Data Security</h3>
    <p style={{ color: '#cccccc' }}>
      We take data security seriously and use reasonable measures to protect
      your information from unauthorized access, alteration, or destruction.
      However, no internet transmission is completely secure.
    </p>

    <h3 style={{ color: '#ffffff' }}>6. Data Retention</h3>
    <p style={{ color: '#cccccc' }}>
      We will retain your personal information only for as long as necessary for
      the purposes outlined in this Privacy Policy or as required by law.
    </p>

    <h3 style={{ color: '#ffffff' }}>7. Your Rights and Choices</h3>
    <p style={{ color: '#cccccc' }}>
      You have the right to access, modify, or delete your personal data. You
      can also object to or restrict certain types of data processing. To
      exercise these rights, please contact us via the email address below.
    </p>
    <p style={{ color: '#cccccc' }}>
      If you wish to disable background location tracking, you can do so in your
      device settings or within the app. However, this may affect some app
      features.
    </p>

    <h3 style={{ color: '#ffffff' }}>8. Children's Privacy</h3>
    <p style={{ color: '#cccccc' }}>
      Our app is not intended for children under 13, and we do not knowingly
      collect personal data from children under 13. If we discover that we have
      collected such data, we will delete it immediately.
    </p>

    <h3 style={{ color: '#ffffff' }}>9. Changes to This Privacy Policy</h3>
    <p style={{ color: '#cccccc' }}>
      We may update this Privacy Policy periodically. Any changes will be posted
      on this page. We recommend reviewing this policy regularly to stay
      informed.
    </p>

    <h3 style={{ color: '#ffffff' }}>10. Contact Us</h3>
    <p style={{ color: '#cccccc' }}>
      If you have any questions about this Privacy Policy, please contact us:
    </p>
    <ul>
      <li style={{ color: '#cccccc' }}>Email: info@oceanlabs.app</li>
      <li style={{ color: '#cccccc' }}>Address: Ocean Labs, Montenegro</li>
    </ul>
  </div>
);

export default PrivacyPolicy;
