import React, { useEffect, useState } from 'react';
import { Spin, Button, Input, Select } from 'antd';
import { useTheme } from 'styled-components';
import { useAuthContext } from '../../../utils';
import axios from 'axios';
import Api from '../../../utils/api';

export const CollapsibleSection = ({ location, user, boat, work }) => {
  const [verifiedAgencies, setVerifiedAgencies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fetchingAI, setFetchingAI] = useState(false);
  const [activeAgencyId, setActiveAgencyId] = useState(null);
  const [selectedAgencyName, setSelectedAgencyName] = useState('');
  const [generatedText, setGeneratedText] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('English');
  const [error, setError] = useState('');
  const { aIModel } = useAuthContext();

  const languageOptions = [
    { value: 'English', label: 'English' },
    { value: 'Russian', label: 'Russian' },
    { value: 'Croatian', label: 'Croatian' },
    { value: 'Turkish', label: 'Turkish' },
    { value: 'Spanish', label: 'Spanish' },
    { value: 'French', label: 'French' },
    { value: 'German', label: 'German' },
    { value: 'Italian', label: 'Italian' },
  ];

  useEffect(() => {
    fetchAgencies();
  }, [location]);

  const fetchAgencies = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/admin/getAgenciesByLocation`,
        { params: { country: location.country, region: location.region } }
      );
      setVerifiedAgencies(response.data.verifiedAgencies);
    } catch (error) {
      console.error('Error fetching agencies:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchGeneratedText = async () => {
    if (!activeAgencyId) return;

    setFetchingAI(true);
    setError('');
    setGeneratedText('');

    const boatType = () => {
      if (boat.type === 'my') return 'Motor Yacht';
      if (boat.type === 'sy') return 'Sailing Yacht';
      if (boat.type === 'ms') return 'Motor Sail';

      return 'Motor Yacht';
    };

    // these can be used in case we need to send partly to AI
    const boatDetails = [
      boat.type && `- Boat Type: ${boatType(boat.type)}`,
      boat.length && `- Length: ${boat.length}m`,
      boat.width && `- Width: ${boat.width}m`,
      boat.draught && `- Draught: ${boat.draught}m`,
      boat.brand && `- Brand: ${boat.brand}`,
      boat.model && `- Model: ${boat.model}`,
      boat.year && `- Year of Manufacture: ${boat.year}`,
    ]
      .filter(Boolean)
      .join(', ');

    const workDetails = [
      work.description && `- Work Description: ${work.description}`,
      work.serviceType && `- Service Type: ${work.serviceType}`,
    ]
      .filter(Boolean)
      .join(', ');

    // const messageContent = `
    //   Prepare a professional email in ${selectedLanguage} to the agency "${selectedAgencyName}" requesting an inquiry about the following service details.

    //   **Subject:** Inspection Request for Service Pricing and Availability

    //   Dear ${selectedAgencyName} Team,

    //   I am reaching out to inquire about the availability and approximate pricing for the following services.

    //   **Boat Details:**
    //   ${boatDetails}

    //   **Work Required:**
    //   ${workDetails}

    //   Could you please provide an estimate for the requested services? If additional details are required to prepare an accurate quote, feel free to let me know.

    //   Looking forward to your response.

    // `.trim();

    // const messageContent = `
    //   service to perform: ${workDetails}
    //   the boat details: ${boatDetails}

    //   prepare an email in ${selectedLanguage} to the agency "${selectedAgencyName}" requesting an offer about the following service details.

    // `;

    const messageContent = `
      OceanLabs firması olarak,
 uzunluğu 27 metre eni 6 metre offshore tipi motoryat cinsi bir tekne için yakıt tanklarındaki yakıt kaçağından mütevellit gerekli kontrollerinin sağlanması, mümkünse tamir edilmesi, değilse değiştirilmesi için gerekli prosedür ve evrak işlerininde dahil edilerek, 
ocean labs te oluşturulmuş bir iş için,
gerekli iş, fiyat ve bilgilendirme için nisamu yachting e bir mail yazmak istiyorum. bu maili kurumsal ve maddelere yazım olarak dikkat ederek sırasıyla benim için yazar mısın
    `;

    // const messageContent = `
    // Prepare content in ${selectedLanguage}
    // Generate an inquiry request for the agency "${selectedAgencyName}" with the following boat and work details:
    // ${boatDetails ? `Boat Details: ${boatDetails}.` : ''}
    // ${workDetails ? `Work Details: ${workDetails}.` : ''}
    // Request a 5% platform discount given as a discount in the offer.
    // `.trim();

    try {
      const response = await Api.post('/ai/askQuestion', {
        userMessage: messageContent,
        model: aIModel,
      });

      if (response && response.choices) {
        setGeneratedText(response.choices[0].message.content);
      } else {
        setError('Failed to generate AI response');
      }
    } catch (error) {
      setError('Error fetching AI response');
      console.error('AI Response Error:', error);
    } finally {
      setFetchingAI(false);
    }
  };

  const handleAgencyClick = (agencyId, agencyName) => {
    if (loading || fetchingAI) return; // Prevent selection while loading
    setActiveAgencyId(agencyId);
    setSelectedAgencyName(agencyName);
    setGeneratedText(''); // Clear previous text when selecting a new agency
  };

  const theme = useTheme();

  return (
    <div
      style={{
        marginTop: '16px',
        padding: '10px',
        backgroundColor: theme.colors.grey80,
        borderRadius: '5px',
      }}
    >
      <h4>Additional Information</h4>
      <p>
        <strong>User:</strong> {user.firstName} {user.lastName} ({user.email})
      </p>
      <p>
        <strong>Boat:</strong> {boat.type} ({boat.length}m length, {boat.width}m
        width, {boat.draught}m draught, {boat.brand}, {boat.model}, {boat.year})
      </p>
      <p>
        <strong>Work:</strong> {work.title}, {work.serviceType},{' '}
        {new Date(work.dueDate).toLocaleDateString()}
      </p>

      <h4>Agencies in this location</h4>

      {loading ? (
        <Spin />
      ) : (
        <div>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
            {verifiedAgencies.map(agency => (
              <Button
                key={agency._id}
                type={activeAgencyId === agency._id ? 'dashed' : 'primary'}
                onClick={() => handleAgencyClick(agency._id, agency.name)}
                disabled={loading || fetchingAI} // Disable during loading or fetching AI response
              >
                {agency.name}
              </Button>
            ))}
          </div>

          {/* TextArea Section Opens Below ALL Buttons */}
          {activeAgencyId && (
            <div
              style={{
                marginTop: '16px',
                padding: '10px',
                backgroundColor: 'grey',
                borderRadius: '5px',
                width: '100%',
              }}
            >
              <h4>Selected Agency: {selectedAgencyName}</h4>

              <Select
                value={selectedLanguage}
                onChange={value => setSelectedLanguage(value)}
                style={{ width: '150px', marginBottom: '8px' }}
                options={languageOptions}
                disabled={fetchingAI} // Disable dropdown when AI is fetching
              />
              <Button
                type='default'
                onClick={fetchGeneratedText} // Request only when this is clicked
                style={{ marginBottom: '8px' }}
                disabled={fetchingAI} // Disable during AI request
              >
                {fetchingAI ? <Spin /> : 'Generate'}
              </Button>

              {error && <p style={{ color: 'red' }}>{error}</p>}

              <Input.TextArea
                value={generatedText}
                rows={8}
                onChange={e => setGeneratedText(e.target.value)}
                placeholder='Generated offer request will appear here...'
                disabled={fetchingAI} // Optional: Disable TextArea during AI fetch
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
