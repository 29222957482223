import React from 'react';

const DataProcessingAgreement = () => (
  <div
    style={{
      padding: '20px',
      maxWidth: '800px',
      margin: '0 auto',
      backgroundColor: '#1a1a1a',
      color: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    }}
  >
    <h2 style={{ color: '#ffffff' }}>Data Processing Agreement</h2>
    <p style={{ color: '#cccccc' }}>Effective Date: 4 November 2024</p>

    <h3 style={{ color: '#ffffff' }}>1. Introduction</h3>
    <p style={{ color: '#cccccc' }}>
      This Data Processing Agreement ("DPA") outlines the terms regarding data
      handling practices in compliance with data protection laws.
    </p>

    <h3 style={{ color: '#ffffff' }}>2. Data Collection</h3>
    <p style={{ color: '#cccccc' }}>
      We collect data for the sole purpose of providing services to our users.
      All data collected is handled in accordance with applicable regulations.
    </p>

    <h3 style={{ color: '#ffffff' }}>3. Background Location Tracking</h3>
    <p style={{ color: '#cccccc' }}>
      Our services may collect user location data even when the app is running
      in the background. This data is collected solely for enabling features
      such as nearby user interactions and location-based notifications.
    </p>
    <p style={{ color: '#cccccc' }}>
      Users have control over this feature and may disable background location
      tracking at any time in their profile settings. However, disabling this
      feature may limit certain functionalities.
    </p>
    <p style={{ color: '#cccccc' }}>
      We do not share real-time location data with third parties, except when
      required by law or when explicitly permitted by the user.
    </p>

    <h3 style={{ color: '#ffffff' }}>4. User Rights</h3>
    <p style={{ color: '#cccccc' }}>
      Users have the right to access, modify, or delete their data upon request,
      subject to applicable laws.
    </p>

    <h3 style={{ color: '#ffffff' }}>5. Security Measures</h3>
    <p style={{ color: '#cccccc' }}>
      We employ reasonable security measures to protect user data from
      unauthorized access or disclosure.
    </p>

    <h3 style={{ color: '#ffffff' }}>6. Data Sharing</h3>
    <p style={{ color: '#cccccc' }}>
      Data is not shared with third parties unless required by law or upon user
      consent.
    </p>

    <h3 style={{ color: '#ffffff' }}>7. Contact Information</h3>
    <p style={{ color: '#cccccc' }}>
      For inquiries regarding data processing, please contact us at:
    </p>
    <ul>
      <li style={{ color: '#cccccc' }}>Email: info@oceanlabs.app</li>
      <li style={{ color: '#cccccc' }}>Address: Ocean Labs, Montenegro</li>
    </ul>
  </div>
);

export default DataProcessingAgreement;
