import styled from 'styled-components';
import { Layout as AntdLayout } from 'antd';

const {
  Header: AntdHeader,
  Sider: AntdSider,
  Content: AntdContent,
} = AntdLayout;

export const Layout = styled(AntdLayout)`
  &&& {
    background-color: transparent;
    height: 100%;
  }

  ${({ theme, $staticHeight, $hideNavbar }) =>
    $staticHeight &&
    `height: calc(100vh - ${
      !$hideNavbar
        ? theme.heights.topMenu + theme.heights.navbar
        : theme.heights.navbar
    }px)`};

  @media (max-width: 730px) {
    flex-direction: column;
    height: 130vh;
  }
`;

export const Header = styled(AntdHeader)`
  padding-top: 24px;
  padding-bottom: 8px;
  height: unset;
  line-height: normal;
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.grey80};
`;

export const TitleWrapper = styled.div`
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
`;

export const Sider = styled(AntdSider)`
  &&& {
    background: transparent;
    width: unset;
    max-width: unset;
    position: sticky;
    top: 24px;
    overflow: auto;

    @media (max-width: 730px) {
      display: flex;
      flex-direction: column;
      height: auto;
      position: relative;
      top: unset;
      overflow: unset;
      display: flex;
    }
  }
`;

export const Content = styled(AntdContent)`
  margin-bottom: ${({ theme }) => theme.spacing.large}px;
  height: auto;
  /* max-height: calc(100vh - 120px); */
  width: 100%;
  overflow-y: auto;

  @media (max-width: 730px) {
    margin-top: 32px;
  }
`;

export const BoldBreadCrumb = styled.span`
  font-weight: 600;
`;
