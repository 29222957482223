import React, { useState, useEffect, useRef } from 'react';
import { Title } from '../Typography';
import { Button, Input, Select, message } from 'antd';
import * as S from './styles';
import { useAuthContext } from '../../../utils/authContext';
import Api from '../../../utils/api';

const buildBreadCrumbs = (breadCrumb) => {
  if (typeof breadCrumb === 'string') return breadCrumb;

  return breadCrumb.map((word, i) => {
    if (i === breadCrumb.length - 1) {
      return <S.BoldBreadCrumb key={word}>{word}</S.BoldBreadCrumb>;
    }

    return `${word} / `;
  });
};

export const Layout = ({
  headerChildren,
  sideBarChildren,
  children,
  title,
  navbar,
  staticHeight = false,
  hideNavbar,
  hideHeader,
  hasAIFeature = false,
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { setToken, setUser, setaIModel, aIModel, superAdmin } = useAuthContext();
  const [error, setError] = useState('');
  const [models, setModels] = useState([]);
  const [selectedAI, setSelectedAI] = useState(null);
  const [tunnelUrl, setTunnelUrl] = useState('');
  const hasFetchedModels = useRef(false);

  const handleLogout = () => {
    setToken('');
    setUser({});
    localStorage.removeItem('token');
    window.location.href = '/admin-x9z7q1w3t8';
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 730);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (!hasAIFeature || hasFetchedModels.current) return; // Only fetch if AI is enabled
    hasFetchedModels.current = true; // Mark as fetched

    const fetchModels = async () => {
      try {
        const response = await Api.get('/ai/getModels');
        const aiModels = response.data;

        if (aiModels.length > 0) {
          setModels(aiModels);
          setSelectedAI(aiModels[0].value); // Default to first model
          setTunnelUrl(response.tunnelUrl);
        }
      } catch (error) {
        console.error('Error fetching models:', error);
        setError('Failed to fetch models data.');
        message.error('Failed to load AI models.');
      }
    };

    fetchModels();
  }, [hasAIFeature]); // Fetch only when hasAIFeature is true

  const updateAiTunnelUrl = async () => {
    try {
      await Api.post('/ai/updateAiTunnelUrl', { aItunnelUrl: tunnelUrl });
      message.success('AI Tunnel URL updated successfully');
    } catch (error) {
      message.error('Failed to update AI Tunnel URL');
    }
  };

  return (
    <S.Layout>
      {headerChildren}

      {!hideHeader && (
        <S.Header>
          <S.TitleWrapper>
            <Button onClick={handleLogout}>Logout</Button>

            {!isSmallScreen && (
              <Title level={2} $transform='capitalize'>
                {title && buildBreadCrumbs(title)}
              </Title>
            )}

            {/* AI Model Selection Dropdown (Only if AI Feature is enabled) */}
            {hasAIFeature && (
              <Select
                value={[aIModel].map((model) => ({
                  value: model,
                  label: model,
                }))}
                onChange={setaIModel}
                style={{ width: 200, marginLeft: '16px' }}
                options={models.map((model) => ({
                  value: model.id,
                  label: model.id,
                }))}
                placeholder='Select AI Model'
                loading={models.length === 0 && !error}
              />
            )}
          </S.TitleWrapper>
          {superAdmin && hasAIFeature && (
            <div style={{ display: 'flex', gap: 16, width: 300 }}>
              <Input value={tunnelUrl} onChange={(e) => setTunnelUrl(e.target.value)} />
              <Button disabled={!tunnelUrl} onClick={updateAiTunnelUrl}>
                Save
              </Button>
            </div>
          )}
          {navbar}
        </S.Header>
      )}

      <S.Layout
        $hasPadding
        hasSider={!!sideBarChildren}
        $staticHeight={staticHeight}
        $hideNavbar={hideNavbar}
        style={isSmallScreen ? { flexDirection: 'column' } : {}}
      >
        {!!sideBarChildren && (
          <S.Sider style={isSmallScreen ? { display: 'flex' } : {}}>
            {sideBarChildren}
          </S.Sider>
        )}
        <S.Content style={isSmallScreen ? { width: 'auto' } : {}}>
          {children}
        </S.Content>
      </S.Layout>
    </S.Layout>
  );
};
