import styled, { keyframes } from 'styled-components';

const blink = keyframes`
  0%, 100% { opacity: 1 }
  50% { opacity: 0 }
`;

export const StyledTypingIndicator = styled.span`
  &::after {
    content: '▌';
    animation: ${blink} 1s step-end infinite;
    margin-left: 2px;
  }
`;

export const ContentWrapper = styled.div`
  display: inline;
`;

export const ChatContainer = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  max-height: 100%;
  overflow: auto;
  /* height: calc(100vh - 120px); */
`;

export const ChatBox = styled.div`
  max-height: 100%;
  overflow-y: auto;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
`;

export const ChatMessage = styled.div`
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  max-width: 70%;
  background-color: ${props => (props.role === 'user' ? '#f0f0f0' : '#007bff')};
  color: ${props => (props.role === 'user' ? 'black' : 'white')};
  text-align: ${props => (props.role === 'user' ? 'left' : 'left')};
  margin-left: ${props => (props.role === 'user' ? 'auto' : '0')};
`;

export const ChatInputForm = styled.form`
  display: flex;
  gap: 10px;
`;

export const Input = styled.input`
  flex-grow: 1;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
`;

export const SendButton = styled.button`
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: #ccc;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
`;
